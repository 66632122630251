<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
          w-reset mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.orders") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
          w-reset
        "
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="termList"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Заказы"
          name="Заказы.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'orders'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <div
      class="col-7 align-items-center align-self-center text-left p-0 mb-2 m-d-flex w-reset"
    ></div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.order_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.total_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.paid_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.color"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.hour"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.doctor_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.order_id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.order_id"
              :placeholder="columns.order_id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
             <el-input
              clearable
              size="mini"
              class="d-block"
              v-model="filterForm.patient"
              :placeholder="$t('message.patient')"
            ></el-input>
            <!-- <Patients v-model="filterForm.patient_id" size="mini"></Patients> -->
          </th>
          <th v-if="columns.total_price.show">
            <!-- <el-input size="mini" v-model="filterForm.total_price" :placeholder="columns.total_price.title" clearable> -->
          </th>
          <th v-if="columns.paid_price.show">
            <!-- <el-input size="mini" v-model="filterForm.paid_price" :placeholder="columns.paid_price.title" clearable> -->
          </th>
          <th v-if="columns.color.show"></th>
          <th v-if="columns.hour.show"></th>
          <th v-if="columns.doctor_id.show">
            <doctors v-model="filterForm.doctor_id" size="mini"></doctors>
          </th>
          <th v-if="columns.service.show">
            <el-input
              size="mini"
              v-model="filterForm.service"
              :placeholder="columns.service.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              size="mini"
            ></el-date-picker>
          </th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="(order, index) in termList"
          class="cursor-pointer"
          :key="'sss' + index"
        >
          <td v-if="columns.order_id.show">{{ order.order_id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              order.patient
                ? order.patient.surname + " " + order.patient.first_name
                : ""
            }}
          </td>
          <td v-if="columns.total_price.show">
            {{ order.total_price | formatMoney }}
          </td>
          <td v-if="columns.paid_price.show">{{ order.paid | formatMoney }}</td>
          <td
            v-if="columns.color.show"
            :style="{
              'background-color': order.color,

              width: '80px',
            }"
          >
            {{ order.is_express ? "express" : "" }}
          </td>
          <td v-if="columns.hour.show">
            {{ order.hour }}
          </td>
          <td v-if="columns.doctor_id.show">
            {{ order.doctor ? order.doctor.surname : "" }}
            {{ order.doctor ? order.doctor.name : "" }}
          </td>
          <td v-if="columns.service.show">
            {{ order.service ? order.service.name : "" }}
          </td>
          <td v-if="columns.created_at.show">{{ order.created_at }}</td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :visible.sync="drawerOrderShow"
      size="80%" :wrapperClosable="false"
      :drawer="drawerOrderShow"
    >
      <crm-show
        @c-close="closeDrawer"
        :order="selectedItem"
        :open="drawerOrderShow"
        drawer="drawerOrderShow"
      ></crm-show>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerOrderUpdate"
      size="80%" :wrapperClosable="false"
      :drawer="drawerOrderUpdate"
      @closed="reloadIfChanged()"
    >
      <crm-update
        @c-close="closeDrawer"
        :order="selectedItem"
        :open="drawerOrderUpdate"
        drawer="drawerOrderUpdate"
      ></crm-update>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerExpired"
      size="90%" :wrapperClosable="false"
      :drawer="drawerExpired"
      @close="reloadIfChanged"
    >
      <div>
        <crm-expired
          :reloadModel="reopenUpdate"
          :reopened="drawerExpired"
          @c-close="reloadIfChanged('drawerExpired')"
        ></crm-expired>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmShow from "./components/crm-show";
import CrmExpired from "./components/crm-expired-show";
import list from "@/utils/mixins/list";
import Update from "./components/crm-update";
import Patients from "@/components/inventory-select/patients";
import doctors from "@/components/inventory-select/doctor_list";
export default {
  mixins: [list],
  components: {
    "crm-show": CrmShow,
    "crm-update": Update,
    Patients,
    doctors,
    CrmExpired,
  },
  data() {
    return {
      drawerOrderShow: false,
      drawerExpired: false,
      drawerOrderUpdate: false,
      filterForm: {},
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      selectedItem: null,
      count: null,
      // list: null,
    };
  },
  computed: {
    ...mapGetters({
      termList: "orders/termList",
      prices: "orders/prices",
      doctor_list: "users/list",
      columns: "orders/columns2",
      pagination: "orders/pagination",
      filter: "orders/filter",
      sort: "orders/sort",
      patientss: "patients/inventory",
    }),
    actions: function () {
      return ["edit", "delete", "show"];
    },
  },

  async mounted() {
    if (this.patientss && this.patientss.length === 0) {
      await this.updateInventory();
    }
    this.fetchData();
    this.controlExcelData();
    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions();
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updateFilter(newVal);
      },
      deep: true,
    },
    sort: {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
    },
    pagination: {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
      deep: true,
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      loadPatients: "patients/index",
      updateList: "orders/orderSericeTerm",
      doctorList: "users/index",
      updateSort: "orders/updateSort",
      updateFilter: "orders/updateFilter",
      updateColumn: "orders/updateColumnNew",
      updatePagination: "orders/updatePagination",
      empty: "orders/empty",
      refreshData: "orders/refreshData",
      loadConditions: "conditions/index",
      updateInventory: "patients/inventory",
    }),
    async fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.count = res.data.order_service_count;
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    edit(model) {
      this.selectedItem = model;
      this.drawerOrderUpdate = true;
    },
    show(model) {
      this.selectedItem = model;
      this.drawerOrderShow = true;
    },

    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
          this.fetchData();
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {
        "ID заказа": "order_id",
        Пациент: {
          field: "patient",
          callback: (value) => {
            return value.surname + " " + value.first_name;
          },
        },
        "Общая сумма": "total_price",
        Оплачено: "paid",
        Часов: "hour",
        Услуга: {
          field: "service",
          callback: (value) => {
            return value.name;
          },
        },
        Доктор: {
          field: "doctor",
          callback: (value) => {
            return value ? value.name + " " + value.surname : "";
          },
        },
        "Дата создания": "created_at",
      };
    },

    emptyModel() {
      this.empty();
    },
  },
};
</script>


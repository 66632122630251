<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.patient_service") }}
            </div>
          </div>
        </el-col>
        {{ rules }}
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <table
      class="table table-bordered table-hover py-2 my-5 mx-2 mr-5"
      v-loading="loadingData"
    >
      <thead>
        <th>{{ $t("message.order_id") }}</th>
        <th>{{ $t("message.patient_id") }}</th>
        <th>{{ $t("message.patient") }}</th>
        <th>{{ $t("message.service") }}</th>
        <th>{{ $t("message.lifetime") }}</th>
        <th>{{ $t("message.condition") }}</th>
        <th>{{ $t("message.order_created_at") }}</th>
        <th>{{ $t("message.order_updated_at") }}</th>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="(order, index) in list"
          class="cursor-pointer"
          :key="'sss' + index"
        >
          <td>{{ order.order.id }}</td>
          <td>{{ order.patient.id }}</td>
          <td>{{ order.patient ? order.patient.surname + " " + order.patient.first_name : '' }}</td>
          <td>{{ order.service.name }}</td>
          <td>{{ order.lifetime }}</td>
          <td>{{ order.condition ? order.condition.name : "" }}</td>
          <td>{{ order.created_at }}</td>
          <td>{{ order.updated_at }}</td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["drawer", "open", "order"],
  data() {
    return {
      waiting: false,
      form: {},
      tablePatientData: [],
      tableServicesData: [],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      list: "orders/expiredList",
      model: "orders/relation_model",
      columns: "orders/columns",
    }),
  },
  methods: {
    ...mapActions({
      loadData: "orders/expiredService",
    }),
    loadOrder() {
      const query = { id: this.order.id, relation: true };
      this.waiting = true;
      this.show(query)
        .then((res) => {
          this.loadModel();
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
        });
    },
    loadModel() {
      this.tablePatientData = [JSON.parse(JSON.stringify(this.model.patient))];
      this.tableServicesData = JSON.parse(
        JSON.stringify(this.model.order_services)
      );
    },
    close() {
      this.$emit("c-close", { drawer: "drawerOrderShow" });
    },
    parent() {
      return this.$parent.$parent;
    },
  },
};
</script>
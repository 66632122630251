<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h" style="display: inline-block">
              <span> {{ $t("message.reception_visit") }}</span>
            </div>
            <div style="display: inline-block">
              <span class="ml-4">
                <el-tag class="mr-2"
                  >{{ $t("message.quantity") }}: {{ list.length }}</el-tag
                >
                <el-tag type="success"
                  >{{ $t("message.total_amount") }}:
                  {{ Math.round(totalAmount) | formatMoney }}</el-tag
                >
              </span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="waiting"
                @click="printDouble(order ? order.id : {})"
                >{{ $t("message.print_double") }}</el-button
              >
              <el-button
                :loading="waiting"
                @click="print(order ? order.id : {})"
                >{{ $t("message.print") }}</el-button
              >
              <el-button
                :loading="waiting"
                :disabled="!yes_user"
                @click="submit(false)"
                type="primary"
                >{{ $t("message.save") }}
              </el-button>
              <el-button :loading="waiting" @click="close()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-tabs class="theePopup" v-model="activeTab" v-loading="waiting">
      <el-tab-pane :label="$t('message.basic_information')" name="first">
        <el-row>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content">
              <div class="add_patient">
                <el-form ref="form" class="from_x">
                  <el-form-item>
                    <el-input
                      v-model="search"
                      @input="searchPatients"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-form>

                <div class="old_patient" v-if="yes_user">
                  <div class="info_old_patient action">
                    <a class="cursor-pointer">
                      <span
                        >{{ user.first_name }} {{ user.surname }}
                        {{ user.patronymic }}</span
                      >
                      <span class="tel_infos">
                        <i class="flaticon-call mr-1"></i>
                        {{ user.phone }}</span
                      >
                    </a>
                    <a
                      class="icon_ad cursor-pointer"
                      @click="openUpdatePatient(user)"
                    >
                      <i class="flaticon-report-1"></i>
                    </a>
                  </div>
                </div>
                <template v-else>
                  <div
                    class="old_patient"
                    v-for="patient in patients"
                    :key="patient.id"
                  >
                    <div class="info_old_patient">
                      <a @click="edit(patient)" class="cursor-pointer">
                        <span
                          >{{ patient.first_name }} {{ patient.surname }}
                          {{ patient.patronymic }}</span
                        >
                        <span class="tel_infos">
                          <i class="flaticon-call mr-1"></i>
                          {{ patient.phone }}</span
                        >
                      </a>
                      <a
                        class="icon_ad cursor-pointer"
                        @click="openUpdatePatient(patient)"
                      >
                        <i class="flaticon-report-1"></i>
                      </a>
                    </div>
                  </div>
                </template>
                <button class="add_pats" @click="addPatient()">
                  {{ $t("message.add_new_patient") }}
                </button>
              </div>
            </div>
          </el-col>
          <!-- end el-col -->

          <template v-if="user && user.id">
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
                <div class="add_patient">
                  <div class="title_cet mb_10">{{ $t("message.cabinet") }}</div>
                  <el-select
                    v-model="form.room_id"
                    placeholder="- не указан -"
                    class="selectRoom"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in rooms"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <div class="title_cet mb_10 mt-3">
                    {{ $t("message.day") }}
                  </div>
                  <el-date-picker
                    :default-value="new Date()"
                    v-model="form.day"
                    size="mini"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                  >
                  </el-date-picker>
                  <div class="textarea mt-3">
                    <div class="sub-title mb-3">{{ $t("message.notes") }}</div>
                    <el-input
                      v-model="form.notes"
                      type="textarea"
                      maxlength="30"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                </div>
              </div>
            </el-col>
            <!-- end el-col -->
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content mb-5">
                <div class="add_patient">
                  <div class="title_cet mb_10">
                    {{ $t("message.doctor_0") }}
                  </div>
                  <div class="radioMarka">
                    <el-col :span="24">
                      <div :label="$t('message.doctor')" prop="doctor_id">
                        <users
                          v-model="form.doctor_id"
                          :doctor_id="form.doctor_id"
                        ></users>
                      </div>
                    </el-col>
                  </div>
                </div>
              </div>
              <div class="grid-content">
                <div class="add_patient">
                  <div class="title_cet mb_10">
                    {{ $t("message.condition") }}
                  </div>
                  <div class="radioMarka">
                    <el-radio-group v-model="form.condition_id">
                      <el-radio
                        v-for="condition in conditions"
                        :key="condition.id"
                        :disabled="condition.status === 'cancel'"
                        :label="condition.id"
                        >{{ condition.name }}</el-radio
                      >
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </el-col>
          </template>
          <!-- end el-col -->
          <el-col v-else :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="grid-content">
              <div class="rihgr_popups">
                <i class="icon_user"></i>
                <h1>{{ $t("message.select_patient") }}</h1>
                <h6>{{ $t("message.select_patient_or_find") }}</h6>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane :label="$t('message.service')" name="second">
        <el-row class="p20">
          <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
            <el-select
              v-model="patientServiceId"
              filterable
              :placeholder="$t('message.start_input_find_service')"
              class="autoInput"
              clearable
            >
              <template v-for="serviceParent in servicesSelected">
                <el-option-group
                  v-if="serviceParent.children.length > 0"
                  :key="serviceParent.id"
                  :label="serviceParent.name"
                >
                  <el-option
                    v-for="service in getChildrens(serviceParent.children)"
                    :key="service.id"
                    :label="service.name"
                    :value="service.id"
                  >
                  </el-option>
                </el-option-group>
                <el-option-group
                  v-else-if="
                    serviceParent.is_service == true &&
                    serviceParent.parent_id == null
                  "
                  :key="serviceParent.id"
                >
                  <el-option
                    :label="serviceParent.name"
                    :value="serviceParent.id"
                  >
                  </el-option>
                </el-option-group>
              </template>
            </el-select>
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
            <el-button
              style="
                font-size: 14px !important;
                padding: 12px !important;
                margin-left: 10px;
              "
              @click="addService"
              >{{ $t("message.add") }}</el-button
            >
            <el-button
              style="font-size: 14px !important; padding: 12px !important"
              @click="drawerPatientService = true"
              >{{ $t("message.add_service") }}</el-button
            >
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <table class="table table-striped table-bordered mt-4">
              <thead>
                <tr>
                  <th>{{ $t("message.pp") }}</th>
                  <th class="wi-50">{{ $t("message.name") }}</th>
                  <th class="wi-7">{{ $t("message.express") }}</th>
                  <th class="wi-7">{{ $t("message.quantity_short") }}</th>
                  <th class="wi-7">{{ $t("message.price2") }}</th>
                  <th class="wi-7">{{ $t("message.discount_percent") }}</th>
                  <th class="wi-7">{{ $t("message.discount_price") }}</th>
                  <th class="wi-7">{{ $t("message.amount") }}</th>
                  <th class="wi-7">{{ $t("message.delete") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ser, index) in list"
                  :key="'order-create-in-header' + index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ ser.name }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <el-checkbox
                        @input="editRow(ser, $event, 'is_express')"
                        :value="ser.is_express"
                        size="small"
                        v-if="express.length"
                      />
                      <div class="px-2">
                        {{ express.length ? express[0].percent + " %" : "" }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="number"
                      id="usr"
                      size="small"
                      step="any"
                      @input="editRow(ser, $event, 'count')"
                      :value="ser.count"
                      :min="1"
                    ></el-input>
                  </td>
                  <td>
                    {{ ser.price | formatMoney }}
                  </td>
                  <td>
                    <el-input-number
                      type="number"
                      size="small"
                      @change="editRow(ser, $event, 'discount_price')"
                      controls-position="right"
                      :value="ser.discount_price"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                  </td>
                  <td>
                    {{
                      Math.round((ser.discount_price * ser.price) / 100)
                        | formatMoney
                    }}
                  </td>
                  <td>
                    {{
                      Math.round(
                        ser.count *
                          expressPrice(ser.is_express) *
                          (ser.price - (ser.price * ser.discount_price) / 100)
                      ) | formatMoney
                    }}
                  </td>
                  <td>
                    <el-button
                      class="d-block"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click.native.prevent="deleteRow(ser)"
                    >
                    </el-button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <th colspan="5">{{ $t("message.total_0") }}</th>
                <th class="w-7">{{ totalDiscount | formatMoney }}</th>
                <th colspan="2">{{ Math.round(totalAmount) | formatMoney }}</th>
              </tfoot>
            </table>
          </el-col>
        </el-row>
        <div class="user-kod">
          <div class="title_cet">{{ $t("message.from_whom") }}</div>
          <div class="user-kod-top">
            <el-select
              class="autoInput"
              v-model="form.partner_doctor_id"
              filterable
              clearable
              :placeholder="$t('message.partner_doctor')"
            >
              <el-option
                v-for="item in partnerDoctors"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <button class="add_pats" @click="drawerPartner = true">
            {{ $t("message.add_new_partner_doctor") }}
          </button>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerCreatePatient"
      direction="rtl"
      size="60%"
      :wrapperClosable="false"
    >
      <CreatePatient
        @c-close="closeDrawerPatient"
        :reopened="drawerCreatePatient"
        drawer="drawerCreatePatient"
      ></CreatePatient>
    </el-drawer>

    <el-drawer
      class="popups_title"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      :append-to-body="true"
      :visible.sync="drawerUpdatePatient"
      direction="rtl"
      size="60%"
      :wrapperClosable="false"
    >
      <UpdatePatient
        @c-close="closeDrawerUpdatePatient"
        :reloadModel="reopenUpdate"
        :patient="selectedPatient"
        :open="drawerUpdatePatient"
        drawer="drawerUpdatePatient"
      ></UpdatePatient>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerPartner"
      direction="rtl"
      size="40%"
      :wrapperClosable="false"
    >
      <AddPartnerDoctor
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerDoctor>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerPatientService"
      direction="rtl"
      size="70%"
      :wrapperClosable="false"
    >
      <CreatePatientService
        :driver="drawerPatientService"
        @c-close="closeDrawerPatientService"
      ></CreatePatientService>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import order from "@/utils/mixins/order"
import users from "@/components/selects/user_list"

export default {
  mixins: [order],
  props: ["order"],
  components: {
    users,
  },
  data() {
    return {
      saveBtn: true,
      printModel: {},
      reopenUpdate: false,
    }
  },
  watch: {
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.loadOrder()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      model: "orders/relation_model",
      express: "expresses/list",
    }),
  },
  mounted() {
    if (this.express && this.express.length === 0) this.loadExpress()
  },
  methods: {
    ...mapActions({
      editPatient: "patients/show",
      editService: "services/show",
      save: "orders/updateOrder",
      editOrder: "orders/relationShow",
      updateSetlist: "services/updateSetlist",
      loadExpress: "expresses/index",
    }),

    submit(close) {
      this.changeWaiting(true)
      const data = {
        id: this.form.id,
        patient_id: this.user.id,
        doctor_id: this.form.doctor_id,
        partner_doctor_id: this.form.partner_doctor_id,
        day: this.form.day,
        notes: this.form.notes,
        condition_id: this.form.condition_id,
        room_id: this.form.room_id,
        services: this.list,
      }
      this.save(data)
        .then((res) => {
          this.changeWaiting()
          this.printModel = res.data ? res.data.order : null
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          })
          this.parent().listChanged()
        })
        .catch((err) => {
          this.changeWaiting()
          this.ifValidationError(err)
        })
    },
    async loadOrder() {
      await this.empty()
      if (!this.waiting && this.order) {
        this.changeWaiting(true)
        const query = {
          id: this.order ? this.order.id : null,
          relation: true,
        }
        this.editOrder(query)
          .then((res) => {
            this.form = JSON.parse(JSON.stringify(this.model))
            this.user = this.form
              ? JSON.parse(JSON.stringify(this.form.patient))
              : {}
            this.noUser(true)
            this.changeWaiting()
            this.updateSetlist(
              JSON.parse(JSON.stringify(this.model.order_services))
            )
              .then((res) => {})
              .catch((err) => {})
          })
          .catch((err) => {
            this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
            this.changeWaiting()
          })
      }
    },
    close() {
      this.empty()
      this.$emit("c-close", {
        drawer: "drawerOrderUpdate",
      })
    },
    expressPrice(is_express) {
      if (is_express && this.express.length) {
        return 1 + this.express[0].percent / 100
      } else {
        return 1
      }
    },
    empty() {
      this.activeTab = "first"
      this.emptyList()
      this.emptySearch()
      this.noUser()
      this.user = null
      this.patientServiceId = ""
      this.search = ""
      this.saveBtn = true
    },
  },
}
</script>

<style lang="scss">
.tableKa .el-table_1_column_7 .cell {
  display: block !important;
}
</style>
